@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root {
    --primary-color : #004b50;
}

@layer base {
    html {
      scroll-behavior: smooth;
    }
  }

body {
    margin: 0;
    font-family: "Inter", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body::before{
    background-image: url(https://app.kwapio.com/static/media/bg1blur.b41e11f2f870d5bdc1b4.webp);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 100%;
    opacity: .10;
    position: fixed;
    width: 100%;
  }



.active {
    /* color: var(--primary-color)!important; */
}

.swiper {
    width: 100% !important ;
    overflow: hidden;
}

.swiper-wrapper {
    width: 100% !important;
    height: 35em !important;
    margin-top: 5rem;
    display: flex !important;
    align-items: center !important;
}

.emp{
  background: #F5F5F5 !important;
}


/* .swiper-slide:not(.swiper-slide-active) {
    filter: blur(1px) !important;
} */

.bg{
  background: var(--primary-color) !important;
}

.brands{
  padding: 20px;
}