.dynamicText {
    opacity: 1;
    transition: opacity 1s ease-in-out;
   
}

.fadeOut {
    opacity: 0;
}

.fadeIn {
    opacity: 1;
}
.primaryColorBtn{
    background-color: var(--primary-color);
    height: 40px;
    align-items: center;
    padding: 0 0.85rem;
}

.sideman{
    animation:side 2s linear forwards;
}

@keyframes side { 
    0% { 
        -webkit-transform: rotate(0deg); 
        transform:rotate(0deg); 
    }
    100% { 
        -webkit-transform: rotate(-30deg); 
        transform:rotate(-30deg); 
    }
}