.wrapper{
    padding: 20px 0;
    h6{
        font-size: 18px;
        font-weight: 600;
        padding: 10px 0;
    }
    .cList{
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        padding: 20px;
        img{
            height: 20px;
          //  width: 280px;
        }
    }
}