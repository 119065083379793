.wrapper{

    .titleColor {
        font-weight: 700;
        color: #c8c9cb !important;
    }
   
    
}

.priceCard{
  background: repeating-conic-gradient(from var(--a), var(--primary-color) 0%,  var(--primary-color) 5%, transparent 5%, transparent 40%,  var(--primary-color) 50%);
  animation: animate 4s linear infinite;
}

@property --a {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg; 
}

@keyframes animate {
  0% {
    --a: 0deg;
  }
  100% {
    --a: 360deg;
  }
}

.priceCard::before {
  content: '';
  position: absolute;
  inset: 4px;
  background-color: white;
  border-radius: 15px;
  border: 4px solid #25252b00;
}


// desktop
@media (min-width: 1400px) {
    .wrapper {
        margin-top: 0;
    }
}
//tab
@media (max-width: 991.98px) {}
// mobile
@media (max-width: 575.98px) {
    .wrapper {
        margin-top: 5rem;
    }
    .priceButton{
       width: 55%;
     }
    .Detailedpricing{
      font-size: 1rem;
    }
}
