.progressContainer {
    width: 100%;
    height: 3px;
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.671);

    .progressBar {
        height: 3px;
        background: var(--primary-color);
        width: 0%;
    }

}

.navBlur {
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.671);
    border-block-end: 1px solid #0505050f;
    height: 56px;
}

.dynamicText {
    opacity: 1;
    transition: opacity 1s ease-in-out;
   
}

.fadeOut {
    opacity: 0;
}

.fadeIn {
    opacity: 1;
}






.thunderIcon {
    animation: flash 3s infinite;
  }
  
  @keyframes flash {
    0%, 100% { 
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  


@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .spin {
    animation: spin 4s linear infinite;
  }
  
 
  

.titleColor {
    font-weight: 700;
    color: #c8c9cb !important;
}

.subCenterTitle {
    font-weight: 600;
}

.textRainbowAnimation {
    background-image: linear-gradient(300deg, rgb(0, 167, 111) 0%, rgb(255, 171, 0) 25%, rgb(0, 167, 111) 50%, rgb(255, 171, 0) 75%, rgb(0, 167, 111) 100%);
    background-position-y: 50%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400%;
    animation: changeColor 15s infinite;
}

@keyframes changeColor {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 300%;
    }

    100% {
        background-position: 0% 50%;
    }
}




.bgLines {
    background-image: linear-gradient(#e6e6e670 1.5px, transparent 1.5px), linear-gradient(to right, #fee8e857 1.5px, transparent 1.5px);
    background-size: 65px 65px;
    background-color: rgba(56, 201, 66, 0);
    border-radius: 10% 30% 50% 70%;
    z-index: 10;
}

.bgImage {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: linear-gradient(180deg, #ffffff 12%, rgba(255 255 255 / 0.92) 50%, #ffff 88%), url(../assets/bg.png);
}


.primaryColor {
    background-color: var(--primary-color);
}

.primaryColorBtn{
    background-color: var(--primary-color);
    height: 40px;
    align-items: center;
    padding: 0 0.85rem;
}

.btnTwo {
    color: #1c252e;
    border-width: .5px;
    height: 40px;
    align-items: center;
    padding: 0 0.85rem;
}

.active {
    color: var(--primary-color) !important;
}

.navHover:hover {
    color: #00000071;
    cursor: pointer;

}


.textScroll {
    overflow-x: hidden;

    svg {

        display: inline-block;
        will-change: transform;

        text {
            animation: marquee 200s linear infinite;
            fill: none !important;
            font-size: 200px;
            font-family: Roboto;
            font-weight: bold;
            text-transform: uppercase;
            stroke-dasharray: 3.5;
            stroke-width: 1;
            stroke: rgba(0, 0, 0, 0.15);
            transform-origin: 2272.2px 92px;
        }
    }

    @keyframes marquee {
        0% {
            transform: translateX(5%);
        }

        100% {
            transform: translateX(-100%);
        }

    }
}

.ballsWrapper {
    width: 90vw;
    position: absolute;
    // z-index: -0;
    height: 100vh;
}

.colorBallOne {
    width: 12px;
    height: 12px;
    top: 40%;
    left: 25%;
    position: relative;

    .ball {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0px -2px 4px 0px #ffab00;
        background: linear-gradient(135deg, #fff5cc, #ffd666);
        animation: TopAndBottom 15s linear infinite;
        animation-timing-function: linear;
    }

    @keyframes TopAndBottom {
        0% {
            top: 0px;
        }

        50% {
            top: 50px;
        }

        100% {
            top: 0px;
        }
    }
}

.colorBallTwo {
    width: 12px;
    height: 12px;
    top: 20%;
    left: 80%;
    position: relative;

    .ball {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0px 0px 4px 0px #00bbd9;
        background: linear-gradient(135deg, #cafdf5, #61f3f3);
        animation: LeftAndRight 15s linear infinite;
        animation-timing-function: linear;
    }

    @keyframes LeftAndRight {
        0% {
            left: 0px;
        }

        50% {
            left: 50px;
        }

        100% {
            left: 0px;
        }
    }
}

.colorBallThree {
    width: 12px;
    height: 12px;
    top: 15%;
    left: 28%;
    position: relative;

    .ball {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0px 0px 4px 0px #ff5630;
        background: linear-gradient(135deg, #ffe9d5, #ffac82);
        animation: LeftAndRight 15s linear infinite;
        animation-timing-function: linear;
    }

    @keyframes LeftAndRight {
        0% {
            left: 0px;
        }

        50% {
            left: 50px;
        }

        100% {
            left: 0px;
        }
    }
}

.colorBallFour {
    width: 12px;
    height: 12px;
    top: 60%;
    left: 75%;
    position: relative;

    .ball {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0px -2px 4px 0px #22c55e;
        background: linear-gradient(135deg, #d3fcd2, #77ed8b);
        animation: TopAndBottom 15s linear infinite;
        animation-timing-function: linear;
    }

    @keyframes TopAndBottom {
        0% {
            top: 0px;
        }

        50% {
            top: 50px;
        }

        100% {
            top: 0px;
        }
    }
}

.colorBallFive {
    width: 12px;
    height: 12px;
    top: 40%;
    left: 70%;
    position: relative;

    .ball {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0px 0px 4px 0px #8e33ff;
        background: linear-gradient(135deg, #efd6ff, #c684ff);
        animation: LeftAndRight 15s linear infinite;
        animation-timing-function: linear;
    }

    @keyframes LeftAndRight {
        0% {
            left: 0px;
        }

        50% {
            left: 50px;
        }

        100% {
            left: 0px;
        }
    }
}

.employeeWrapper{
    margin-top: 2rem;
}
.whyChooseWrapper{
    margin-top: 0;
}




// 2 ==========================================================================================================================




// desktop
@media (min-width: 1400px) {

}
//tab
@media (max-width: 991.98px) {}
// mobile
@media (max-width: 575.98px) {
    .employeeWrapper{
        margin-top: 0;
    }
    
}